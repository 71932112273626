.ant-table-pagination.ant-pagination {
    float: none !important;
    display: flex;
    justify-content: center;
}

.user {
    &__content {
        width: 100%;

        &--padding {
            padding: 3vw;
        }
    }
}



.options {
    padding-top: 3.5vh;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    &__search {
        margin-right: 20px;

        &__input {
            width: 200px;
        }

        &__btn {
            &--cancel {
                margin-right: 5px;
            }
        }
    }

    &__container-icon {
        position: relative;
        display: inline-block;

        &__icon {
            padding-top: 6px;
            margin-right: 20px;
        }

        &__iconPopup-user {
            display: none !important;
            top: -24px;
            left: -74px;
            background-color: black;
            color: #fff;
            width: 140px;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            display: block;
            position: absolute;
            z-index: 1;
        }

        &:hover &__iconPopup-user {

            display: block !important;
            opacity: 0.7;
        }
    }
}

.table {
    margin-top: 1.5vh;

}