.login-form {
    max-width: 300px;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

// input {
//     width: 300px !important;
// } AI BELIT PULA DOMNULE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// button {
//     width: 300px !important; AICI TOT
// }