.modalBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.template__radioSelector {
    width: 90%;
    display: flex;
    margin-top: 8px;
}


.items {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.template__content {
    width: 90%;
}

.items__template {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 300px;
    background-color: #202020;
    border-radius: 5px;
}

.template__author {
    display: flex;
    flex-direction: row;
    color: white;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
}

.template__text {
    width: 100%;
    color: white;
    margin-top: 10px;
}

.template__pickButton {
    display: flex;
    justify-content: center;
    width: 200px;
    height: 34px;
    border: none;
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    color: #202020;
    margin-bottom: 10px;
    padding-top: 7px;
    outline: none;
    font-weight: 700;

}

.template__pickButtonSelected {
    display: flex;
    justify-content: center;
    width: 200px;
    height: 34px;
    border: none;
    background-color: #3E91F7;
    border-radius: 3px;
    color: #ffffff;
    margin-bottom: 10px;
    padding-top: 7px;
    outline: none;
    font-weight: 700;
}

.template__pickButton:hover {
    width: 200px;
    height: 34px;
    border: none;
    background: #FFFFFF;
    border-radius: 3px;
    color: #202020;
    margin-bottom: 10px;
    padding-top: 7px;
    cursor: pointer;
    outline: none;
}

.template__pickButton:active {
    width: 200px;
    height: 34px;
    border: none;
    background: rgb(216, 216, 216);
    border-radius: 3px;
    color: #202020;
    margin-bottom: 10px;
    padding-top: 7px;
    cursor: pointer;
    outline: none;
}


.pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 25px;

}

.template__text--overflow {
    color: white;
    overflow: hidden;
    width: 100%;
    height: 120px;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.ant-pagination-item {
    color: rgba(0, 0, 0, 0.65);
}

.ant-pagination-item-active {
    border-color: #202020;
}

.ant-pagination-item:hover {
    border-color: #202020;
    color: #202020;
}



.ant-pagination-item:focus a {
    color: #202020;
}

.ant-pagination-item:hover a {
    color: #202020;
}

.ant-pagination-item-active a {
    color: #202020;

}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
    color: #202020;
    border-color: #202020;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    color: #202020;
    border-color: #202020;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link {
    color: #202020;
    border-color: #202020;

}