.statistics-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}


.select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin-top: 40px;
  margin-bottom: 20px;

  &__dropdown {
    margin-left: 10px;
    display: inline-block;
  }
}

.statistics {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.25);
    border-color: rgba(223, 225, 229, 0);
    border-radius: 5px;
    width: 85%;
  }

  &__charts {
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: space-between;
    margin-bottom: 15px;

    &--satisfaction-chart {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.25);
      width: 49%;
      border-color: rgba(223, 225, 229, 0);
      border-radius: 5px;
    }

    &--hired-chart {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.25);
      width: 49%;
      border-color: rgba(223, 225, 229, 0);
      border-radius: 5px;
    }
  }


}

.data-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  &__row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;

    &__pie-chart {
      margin-top: 20px;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f1f1f1;
      width: 27%;
      height: 250px;
      border-radius: 7px;

      &__title {
        margin-top: 15px;
        font-weight: 500;
      }

      &--content {
        margin-top: 20px;

        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        width: 93%;
        justify-content: center;
      }

      &__legend {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        &__title {
          margin-bottom: 2px;
          font-weight: 420;
        }

        &--red {
          background-color: #D37575;
          width: 68px;
          height: 4px;
          border-radius: 2px;

        }

        &--blue {
          background-color: #69B8F1;
          width: 68px;
          height: 4px;
          border-radius: 2px;
          margin-bottom: 6px;
        }

        &--green {
          background-color: #5DC6A0;
          width: 68px;
          height: 4px;
          border-radius: 2px;
        }

        &--yellow {
          background-color: #DFBD65;
          width: 68px;
          height: 4px;
          border-radius: 2px;
          margin-bottom: 6px;

        }

      }
    }

    &__cell {
      width: 100px;

    }
  }

  &__divider {
    width: 95%;
    margin-top: 15px;
    height: 1px;
    background-color: #e4e4e4;
  }

}