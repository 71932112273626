.uploadInput {
    display: none;
}

.avatarContainer {
    display: 'flex';
    flex-direction: 'row';
    margin-left: '98px';
}


.pickPhotoButton {
    color: white;
    background-color: #3E91F7;
    border: none;
}

.pickPhotoButton:hover {
    color: #3E91F7;
    background-color: rgb(255, 255, 255);
    border: 1px solid #3E91F7;
}

.pickPhotoButton:focus {
    color: #3E91F7;
    background-color: rgb(255, 255, 255);
    border: 1px solid #3E91F7;
}


.avatar-picture {
    margin-left: 97px;
    margin-bottom: 20px;
}