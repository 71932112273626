.content-editor {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-items: center;

}

.template {
  width: 65%;
  background-color: rgb(39, 39, 39);
  box-shadow: inset -5px -2px 12px 0 rgba(0, 0, 0, 0.7);

  &-divider {
    width: 100%;
    height: 10vh;
  }

  &-frame {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    border: none;
  }
}

.editor {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-title {
    font-size: 1.5em;
  }



  &-input {
    margin-bottom: 20px;
    width: 100%;
  }

  &-quill {
    height: 40vh;
    width: 30vw;
    word-wrap: break-word;
    margin-top: 20px;
  }
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button {
  &-cancel {
    margin-top: 60px;
    width: 49%;
    background-color: white;
    color: #1b2938;
    border: 1px solid rgb(209, 209, 209);
  }

  &-submit {
    margin-top: 60px;
    width: 49%;
    background-color: #1b2938;
    color: white;
    border: none;
  }
}