.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        background-color: #f1f1f1;
        width: 100%;
        height: 40vh;
    }

    &__avatar {
        margin-top: -85px;
    }

    &__personName {
        margin-top: 20px;
        font-size: 1.5em;
    }

    &__personRole {
        margin-top: -20px;
        font-size: 1.2em;
        color: #959595;
    }

}

.button {
    outline: 0;

    &--edit {
        width: 120px;
        height: 30px;
        border-radius: 5px;
        background-color: white;
    }
}