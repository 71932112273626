.width_80 {
    width: 80vw;
}

.main-content {
    width: 100%;

}

.thanks-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__header {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 200px;
        background-color: #f3f3f3;
        border-bottom-left-radius: 70% 20%;
        border-bottom-right-radius: 100% 70%;

        //bent
        @media only screen and (max-width: 768px) {
            border-bottom-left-radius: 42% 12%;
            border-bottom-right-radius: 100% 37%;

        }

        @media only screen and (max-width: 680px) {
            height: 180px;
            border-bottom-left-radius: 42% 12%;
            border-bottom-right-radius: 100% 37%;
        }

        @media only screen and (max-width: 589px) {
            height: 150px;
        }

        @media only screen and (max-width: 360px) {
            height: 100px;
        }

        &__logo {
            margin-top: 70px;
            height: 50px;

            @media only screen and (max-width: 589px) {
                height: 40px;
            }
        }
    }

    &__thanks-message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 60px;

        &--completed {
            font-size: 2.2em;
            color: #1D2939;
            line-height: 1em;

            @media only screen and (max-width: 496px) {
                font-size: 2em;
                color: #1D2939;
                line-height: 1em;
                text-align: center;

            }
        }

        &--thanks {
            font-size: 2.5em;
            color: #1D2939;

            @media only screen and (max-width: 496px) {
                font-size: 2em;
                color: #1D2939;
                line-height: 1em;
                text-align: center;

            }
        }

    }

}

.form-page {
    width: 100%;
}

.form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__header {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 200px;
        background-color: #f3f3f3;
        border-bottom-left-radius: 70% 20%;
        border-bottom-right-radius: 100% 70%;

        //bent
        @media only screen and (max-width: 768px) {
            border-bottom-left-radius: 42% 12%;
            border-bottom-right-radius: 100% 37%;

        }

        @media only screen and (max-width: 680px) {
            height: 180px;
            border-bottom-left-radius: 42% 12%;
            border-bottom-right-radius: 100% 37%;
        }

        @media only screen and (max-width: 589px) {
            height: 150px;
        }

        @media only screen and (max-width: 360px) {
            height: 100px;
        }

        &__logo {
            margin-top: 70px;
            height: 50px;

            @media only screen and (max-width: 589px) {
                height: 40px;
            }
        }
    }

    &__radio-page {
        width: 100%;
        margin-top: 60px;
    }
}

.employment-content {
    &--radios {
        display: flex;
        justify-content: center;
    }

    &__form {
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;



        &__title {
            line-height: 1;
            color: #1D2939;

            @media only screen and (max-width: 505px) {
                margin-left: 30px;
                margin-right: 30px;
            }
        }

        &__button {
            &--hired {
                @media only screen and (max-width: 434px) {
                    width: 200px;
                    border-radius: 4px 4px 4px 4px !important;
                    margin-top: 3px;
                }

                @media only screen and (max-width: 360px) {
                    width: 200px;
                    border-radius: 4px 4px 4px 4px !important;
                    margin-top: 3px;
                }
            }

            &--otherDomain {
                @media only screen and (max-width: 680px) {
                    border-radius: 0px 4px 4px 0px !important;
                }

                @media only screen and (max-width: 505px) {
                    border-radius: 0px 4px 4px 0px !important;
                }

                @media only screen and (max-width: 434px) {
                    width: 200px;
                    border-radius: 0px 4px 4px 0px !important;
                }

                @media only screen and (max-width: 360px) {
                    width: 200px;
                    border-radius: 4px 4px 4px 4px !important;
                    margin-top: 3px;
                }
            }

            &--continueStudy {
                @media only screen and (max-width: 589px) {
                    border-radius: 4px 0px 0px 4px !important;
                }

                @media only screen and (max-width: 434px) {
                    width: 200px;
                    border-radius: 4px 0px 0px 4px !important;
                }

                @media only screen and (max-width: 360px) {
                    width: 200px;
                    border-radius: 4px 4px 4px 4px !important;
                    margin-top: 3px;
                }
            }

            &--others {
                @media only screen and (max-width: 680px) {
                    width: 530px;
                    margin-top: 5px;
                    border-radius: 4px 4px 4px 4px !important;
                }

                @media only screen and (max-width: 589px) {
                    width: 219px;
                    margin-top: 5px;
                    border-radius: 0px 4px 4px 0px !important;
                }

                @media only screen and (max-width: 434px) {
                    width: 200px;
                    margin-top: 5px;
                }

                @media only screen and (max-width: 360px) {
                    width: 200px;
                    border-radius: 4px 4px 4px 4px !important;
                    margin-top: 3px;
                }
            }
        }

        &__input {
            &--workplace {

                @media only screen and (max-width: 680px) {
                    width: 530px;
                    justify-content: center;
                }

                @media only screen and (max-width: 589px) {
                    width: 375px;
                    justify-content: center;
                }

                @media only screen and (max-width: 505px) {
                    justify-content: center;
                }

                @media only screen and (max-width: 434px) {
                    width: 200px;
                    border-radius: 4px 4px 4px 4px !important;
                }

                @media only screen and (max-width: 360px) {
                    width: 200px;
                    border-radius: 4px 4px 4px 4px !important;
                }
            }
        }
    }

    &__radioGroup-others {
        display: flex;
        justify-content: center;
        align-items: center;


    }
}

.radioGroup-others {
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__radios {
            display: flex;
            flex-direction: row;

            @media only screen and (max-width: 505px) {
                display: flex;
                flex-direction: column;
            }

            &--column {
                display: flex;
                flex-direction: column;
            }



        }
    }
}

.satisfaction-rating {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @media only screen and (max-width: 680px) {
        margin-left: 15px;
        margin-right: 15px;
    }

    &__form {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        text-align: center;

        &__title {
            line-height: 1;
            color: #1D2939;

            @media only screen and (max-width: 505px) {
                margin-left: 30px;
                margin-right: 30px;
            }

        }

        &__item {

            &--level-best {
                @media only screen and (max-width: 360px) {
                    width: 200px;
                    margin-top: 5px;
                    border-radius: 4px 4px 4px 4px !important;
                }
            }

            &--level-good {
                @media only screen and (max-width: 360px) {
                    width: 200px;
                    margin-top: 5px;
                    border-radius: 4px 4px 4px 4px !important;
                }
            }

            &--level-ok {
                @media only screen and (max-width: 360px) {
                    width: 200px;
                    margin-top: 5px;
                    border-radius: 4px 4px 4px 4px !important;
                }
            }

            &--level-bad {
                @media only screen and (max-width: 505px) {
                    width: 304px;
                    margin-top: 5px;
                    border-radius: 4px 4px 4px 4px !important;
                }

                @media only screen and (max-width: 360px) {
                    width: 200px;
                    margin-top: 5px;
                    border-radius: 4px 4px 4px 4px !important;
                }
            }

        }
    }
}

.submit-content {
    display: flex;
    justify-content: center;
    margin-top: 0px;

    &__button {

        &--submit {
            background-color: #1D2939;
            border: #1D2939;
        }

        &:focus {
            background-color: #1D2939;
            border: #1D2939;
        }
    }
}

.gif {
    width: 480px;
    height: 270px;

    @media only screen and (max-width: 490px) {
        width: 400px;
        height: 200px;
    }

    @media only screen and (max-width: 410px) {
        height: 150px;
        width: 300px;
    }

}

.ant-form label {
    font-size: 16px !important;
}

.ant-radio-button-wrapper {
    padding-left: 18px;
    padding-right: 18px;
}

.ant-radio-wrapper {
    margin-bottom: 6px !important;
}